.building-table {
    width: 100%;
    text-align: center;
    tbody {
        tr:hover {
        z-index: 9999;
        border: solid 4px #1888ff;
        cursor: pointer;
        td {
            background-color: #bbdefb !important;
        }
        }
    }
}


.container-infobox{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px;
    width: 80%;
}

.info-textbox {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border: 2px solid #ccc;
    border-radius: 5px;
    font-weight: bold;
    padding: 1rem;
    margin: 1rem;
    width: 20%;
    height: 20%;
}

.info-textbox .icon {
    width: clamp(1.5em, 30%, 30%);
    height: clamp(1.5em, 30%, 30%);
    display: inline-block;
    margin: 5% 10% 5% 5%;
}

.info-textbox span {
    font-size: 3rem;
    display: inline-block;
    vertical-align: top;
}

.info-textbox h2 {
    font-size: 1.4rem;
    text-align: center;
}

@media (max-width: 1300px) {
    .container-infobox {
        width: 100%;
        height: 150px;
        margin: 30px 0;
    }

    .info-textbox {
        margin: 5px;
        height: 100%;    
    }

    .info-textbox span {
        font-size: clamp(1rem, 2vw, 2rem);
        vertical-align: middle;
    }
}

@media (max-width: 600px) {
    .container-infobox {
        height: 120px;
        margin: 10px 0;
    }
    .info-textbox {
        margin: 1px;
    }
    .info-textbox .icon {
        display: block;
        margin: 0 auto;
    }
    .info-textbox span {
        display: block;
        font-size: 1rem;
        text-align: center;
    }
    .info-textbox h2 {
        font-size: .7rem;
    }
}

.container-layout {
  display: flex;
  justify-content: space-between;
  gap: 10px;

}

.container-layout::after {
  content: '';
  flex: 0;
}


.pagination {
  display: flex;
  margin: 20px 0; /* Margin top & bottom */
  justify-content: center;
  button {
    border: 1px solid #ccc;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    margin: 0 5px; /* Margin left & right */
    cursor: pointer;
  }
  button:disabled {
    background-color: #e0e0e0; /* Grey background for disabled buttons */
    color: #a0a0a0; /* Grey text for disabled buttons */
    cursor: default;
    border: 1px solid #d0d0d0;
  }
}
